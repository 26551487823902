import React, { useState } from 'react'
import Layout from '../../components/Layout'

import { Link } from 'gatsby'
import { SubscriptionForm } from '../../components/SubscriptionForm'
import SEO from '../../components/seo'
import { Post } from '../../components/Post'
import { graphql } from 'gatsby'
import { red, grey, purple, green, orange } from '@material-ui/core/colors'
import { Divider, Grid, Typography, Button, Paper } from '@material-ui/core'
import PostList from '../../components/PostList'
import Point from '../../components/Point'

const PostsPreview = ({ posts }) => (
  <>
    {posts.map(({ node }) => {
      const { title } = node.frontmatter
      const { slug } = node.fields || {}
      return (
        <Typography key={title} gutterBottom color={'textSecondary'}>
          {title}
        </Typography>
      )
    })}
  </>
)
const PostsWithImages = ({ posts }) => (
  <>
    {posts.map(({ node }) => {
      return (
        <Post
          slug={node.fields.slug}
          title={node.frontmatter.title || node.fields.slug}
          cover={node.frontmatter.cover}
        />
      )
    })}
  </>
)

const mapArrToPosts = arr =>
  arr.map(title => ({
    node: {
      frontmatter: {
        title,
      },
    },
  }))

const MY_BENEFITS = [
  'Make me value time more',
  'Improve Health',
  'Improve productivity',
  'Improve Focus',
  'Allowed to build great habats',
  'Reduce my anxity',
  'Structure my day',
]

const STEPS_ABOUT_POMODORO = [
  {
    title: 'Choose a task',
  },
  {
    title: 'Start a timer (e.g. 25 m).',
  },
  {
    title: 'Start a work session — work on your task',
  },
  {
    title: 'When time is out put a checkmark on a paper',
  },
  {
    title: 'Take a short break. Every 4 work sessions take a longer break',
  },
]

const Princinples = () => {
  return (
    <Grid container>
      {['Time Boxing', 'Task Awareness', 'Recovery Management'].map(title => (
        <Grid item md={4}>
          <Typography
            variant={'h6'}
            align={'center'}
            style={{ padding: '40px 20%' }}
          >
            {' '}
            {title}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}

export default props => {
  const {
    data: {
      site: {
        siteMetadata: { title },
      },
    },
    location,
  } = props

  const [showDescription, setShowDescription] = useState(false)
  return (
    <>
      <Layout location={location} title={title}>
        <SEO
          title={'Pomodoro Technique'}
          keywords={[`pomodoro`, `technique`, `personal experiecen`]}
        />
        <Typography variant={'h3'} paragraph>
          My Goals
        </Typography>
        <Typography variant={'caption'} color={'textSecondary'} paragraph>
          Draft #1
        </Typography>
        <Typography paragraph>
          My very first serious public statement!
        </Typography>
        <br />
        <br />
        <Typography paragraph variant={'h4'}>
          What?
        </Typography>
        <Point palette={red}>Build products</Point>
        <br />
        <br />
        <Typography paragraph variant={'h4'}>
          Why?
        </Typography>
        <Point palette={red}>
          To gain resources (such as knowledge, experience and money)
        </Point>
        <br />
        <br />
        <Typography paragraph variant={'h4'}>
          Why?
        </Typography>
        <Point palette={red}>
          To improve my health, learn from masters and decide what to devote my
          life to.
        </Point>
        <br />
        <br />
        <Typography paragraph variant={'h4'}>
          How?
        </Typography>
        <Point palette={red}>Build a mobile app for dancers</Point>

        <Typography variant={'overline'} gutterBottom>
          Subscribe
        </Typography>
        <SubscriptionForm />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`
